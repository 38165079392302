import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import { UrgentCare } from '../types';

// Fix for default marker icon
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

const defaultIcon = new Icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

interface MapProps {
  centers: UrgentCare[];
  center: [number, number];
  zoom?: number;
}

export function Map({ centers, center, zoom = 13 }: MapProps) {
  return (
    <MapContainer
      center={center}
      zoom={zoom}
      className="w-full h-[600px] rounded-lg"
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {centers.map((center) => (
        <Marker
          key={center.id}
          position={[center.location.lat, center.location.lon]}
          icon={defaultIcon}
        >
          <Popup>
            <div className="p-2">
              <h3 className="font-semibold">{center.name}</h3>
              <p className="text-sm text-gray-600">{center.address.street}</p>
              <p className="text-sm text-gray-600">
                {center.address.city}, {center.address.state} {center.address.zip}
              </p>
              {center.phone && (
                <p className="text-sm text-gray-600 mt-1">{center.phone}</p>
              )}
            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}