import React from 'react';
import { Helmet } from 'react-helmet-async';
import { LocationData } from '../types';
import { generateUniqueContent } from '../utils/contentGenerator';

interface LocationSEOProps {
  locationData: LocationData;
  canonicalUrl: string;
}

export function LocationSEO({ locationData, canonicalUrl }: LocationSEOProps) {
  const content = locationData.city && locationData.state && locationData.zip
    ? generateUniqueContent(locationData.city, locationData.state, locationData.zip)
    : {
        title: `Urgent Care Centers in ${locationData.name}`,
        description: locationData.description,
        content: []
      };

  const schema = {
    "@context": "https://schema.org",
    "@type": "MedicalOrganization",
    "name": content.title,
    "description": content.description,
    "address": {
      "@type": "PostalAddress",
      "addressRegion": locationData.state,
      "addressLocality": locationData.city,
      "postalCode": locationData.zip
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": locationData.lat,
      "longitude": locationData.lon
    },
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        "opens": "08:00",
        "closes": "20:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": ["Saturday", "Sunday"],
        "opens": "09:00",
        "closes": "17:00"
      }
    ],
    "medicalSpecialty": [
      "Urgent Care",
      "Walk-in Clinic",
      "Primary Care"
    ]
  };

  return (
    <Helmet>
      <title>{content.title} | UrgentCareNearMe</title>
      <meta name="description" content={content.description} />
      <link rel="canonical" href={`https://urgentcarenearme.today${canonicalUrl}`} />
      
      <meta property="og:title" content={content.title} />
      <meta property="og:description" content={content.description} />
      <meta property="og:url" content={`https://urgentcarenearme.today${canonicalUrl}`} />
      <meta property="og:type" content="website" />
      
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={content.title} />
      <meta name="twitter:description" content={content.description} />
      
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  );
}