import { locations } from '../data/locations';

export interface LocationData {
  city?: string;
  state?: string;
  zip?: string;
  name: string;
  description: string;
  lat: number;
  lon: number;
}

export interface ContentTemplate {
  title: string;
  description: string;
  content: string[];
}

export function generateUniqueContent(city: string, state: string, zip: string): ContentTemplate {
  const nearbyStates = locations
    .filter(loc => loc.state !== state)
    .slice(0, 3)
    .map(loc => loc.state);

  const stateData = locations.find(loc => loc.state === state);
  const nearbyCities = stateData?.cities
    .filter(c => c.name !== city)
    .slice(0, 5)
    .map(c => c.name) || [];

  return {
    title: `Urgent Care Centers in ${city}, ${state} (${zip})`,
    description: `Find top-rated urgent care centers in ${city}, ${state}. Compare wait times, read reviews, and book same-day appointments. Serving ZIP code ${zip} and surrounding areas.`,
    content: [
      `Looking for urgent care in ${city}, ${state}? We've compiled a comprehensive list of walk-in clinics and urgent care centers serving the ${zip} area. Our real-time updates show current wait times and available services.`,
      `${city} residents can choose from multiple urgent care locations, offering convenient alternatives to emergency rooms for non-life-threatening conditions. Many centers in the ${zip} area provide extended hours and weekend availability.`,
      `The ${city} metropolitan area, including nearby ${nearbyCities.slice(0, 2).join(' and ')}, offers numerous urgent care options. Whether you're in downtown ${city} or surrounding neighborhoods, you'll find accessible medical care.`,
      `Patients from ${state} and neighboring ${nearbyStates.slice(0, 2).join(' and ')} frequently visit ${city}'s urgent care facilities for their comprehensive services and shorter wait times compared to emergency rooms.`,
      `Most urgent care centers in ${city}'s ${zip} ZIP code area accept major insurance plans and offer affordable self-pay options. Many locations feature on-site lab testing, X-ray services, and experienced medical staff.`
    ]
  };
}

export function generateLocationData(state?: string, city?: string, zip?: string): LocationData | null {
  if (zip) {
    for (const stateData of locations) {
      const cityData = stateData.cities.find(c => c.zip === zip);
      if (cityData) {
        return {
          city: cityData.name,
          state: stateData.state,
          zip,
          name: `${cityData.name}, ${stateData.state}`,
          description: `Find urgent care centers and walk-in clinics in ZIP code ${zip}. Compare wait times, services, and book appointments.`,
          lat: cityData.lat,
          lon: cityData.lon
        };
      }
    }
  } else if (state && city) {
    const stateData = locations.find(s => s.state.toLowerCase() === state.toLowerCase());
    if (stateData) {
      const cityData = stateData.cities.find(
        c => c.name.toLowerCase() === city.toLowerCase().replace(/-/g, ' ')
      );
      if (cityData) {
        return {
          city: cityData.name,
          state: stateData.state,
          zip: cityData.zip,
          name: `${cityData.name}, ${stateData.state}`,
          description: `Discover urgent care locations in ${cityData.name}, ${stateData.state}. Get directions, hours, and real-time availability.`,
          lat: cityData.lat,
          lon: cityData.lon
        };
      }
    }
  } else if (state) {
    const stateData = locations.find(s => s.state.toLowerCase() === state.toLowerCase());
    if (stateData) {
      const centerCity = stateData.cities[0];
      return {
        state: stateData.state,
        name: stateData.state,
        description: `Browse urgent care facilities across ${stateData.state}. Find the nearest location with shortest wait times.`,
        lat: centerCity.lat,
        lon: centerCity.lon
      };
    }
  }
  return null;
}