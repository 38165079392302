import { useEffect, useState } from 'react';
import { LocationData, generateLocationData } from '../utils/contentGenerator';

export function useLocation(state?: string, city?: string, zip?: string) {
  const [locationData, setLocationData] = useState<LocationData | null>(null);
  const [coordinates, setCoordinates] = useState<{ lat: number; lon: number } | null>(null);

  useEffect(() => {
    const data = generateLocationData(state, city, zip);
    if (data) {
      setLocationData(data);
      setCoordinates({ lat: data.lat, lon: data.lon });
    }
  }, [state, city, zip]);

  return { locationData, coordinates };
}