import React from 'react';
import { Stethoscope, Clock, ClipboardCheck, Syringe, FileText, Activity } from 'lucide-react';

export function Services() {
  const services = [
    {
      icon: <Stethoscope className="h-8 w-8" />,
      title: 'General Medical Care',
      description: 'Treatment for non-life-threatening illnesses and injuries.',
    },
    {
      icon: <Clock className="h-8 w-8" />,
      title: 'Wait Time Updates',
      description: 'Real-time information about facility wait times.',
    },
    {
      icon: <ClipboardCheck className="h-8 w-8" />,
      title: 'Online Check-in',
      description: 'Reserve your spot in line at participating locations.',
    },
    {
      icon: <Syringe className="h-8 w-8" />,
      title: 'Vaccinations',
      description: 'Routine immunizations and flu shots.',
    },
    {
      icon: <Activity className="h-8 w-8" />,
      title: 'Minor Injuries',
      description: 'Treatment for sprains, cuts, and minor burns.',
    },
    {
      icon: <FileText className="h-8 w-8" />,
      title: 'Lab Services',
      description: 'On-site testing and diagnostic services.',
    },
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Our Services</h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto">
          Discover how we can help you find and access the urgent care services you need.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <div
            key={index}
            className="bg-white rounded-lg p-8 shadow-lg hover:shadow-xl transition-shadow"
          >
            <div className="text-red-500 mb-4">{service.icon}</div>
            <h3 className="text-xl font-semibold text-gray-900 mb-2">{service.title}</h3>
            <p className="text-gray-600">{service.description}</p>
          </div>
        ))}
      </div>

      <div className="mt-16 bg-gray-50 rounded-lg p-8 text-center">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Need Urgent Care?</h2>
        <p className="text-gray-600 mb-6">
          Find the nearest urgent care center and get the care you need, when you need it.
        </p>
        <a
          href="/"
          className="inline-flex items-center px-6 py-3 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 shadow-md transition-colors"
        >
          Find Care Now
        </a>
      </div>
    </div>
  );
}