import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
import { Layout } from './components/Layout';
import { Home } from './pages/Home';
import { CenterDetails } from './pages/CenterDetails';
import { About } from './pages/About';
import { Services } from './pages/Services';
import { LocationPage } from './pages/LocationPage';
import { NotFound } from './pages/NotFound';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      retry: 3,
    },
  },
});

function App() {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="center/:id" element={<CenterDetails />} />
              <Route path="about" element={<About />} />
              <Route path="services" element={<Services />} />
              <Route path="urgent-care/zip-code/:zip" element={<LocationPage />} />
              <Route path="urgent-care/:state/:city" element={<LocationPage />} />
              <Route path="urgent-care/:state" element={<LocationPage />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;