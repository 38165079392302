import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { MapPin, Phone, Clock, Globe, ArrowLeft, Loader2 } from 'lucide-react';
import { Map } from '../components/Map';
import { useCenterDetails } from '../hooks/useCenterDetails';

export function CenterDetails() {
  const { id } = useParams();
  const { data: center, isLoading, error } = useCenterDetails(id || '');

  if (isLoading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex items-center justify-center min-h-[400px]">
          <Loader2 className="h-8 w-8 animate-spin text-red-500" />
        </div>
      </div>
    );
  }

  if (error || !center) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white shadow-lg rounded-lg p-8">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">Error</h1>
          <p className="text-gray-600 mb-4">
            Sorry, we couldn't find details for this urgent care center.
          </p>
          <Link
            to="/"
            className="inline-flex items-center text-red-500 hover:text-red-600"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Search
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <Link
        to="/"
        className="inline-flex items-center text-red-500 hover:text-red-600 mb-6"
      >
        <ArrowLeft className="h-4 w-4 mr-2" />
        Back to Search
      </Link>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div>
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="p-8">
              <h1 className="text-3xl font-bold text-gray-900 mb-6">
                {center.name}
              </h1>

              <div className="space-y-4">
                <div className="flex items-start">
                  <MapPin className="h-5 w-5 mr-3 text-gray-400 flex-shrink-0 mt-1" />
                  <div>
                    <h2 className="font-semibold text-gray-900">Address</h2>
                    <p className="text-gray-600">
                      {center.address.street}
                      <br />
                      {center.address.city}, {center.address.state} {center.address.zip}
                    </p>
                  </div>
                </div>

                {center.phone && (
                  <div className="flex items-start">
                    <Phone className="h-5 w-5 mr-3 text-gray-400 flex-shrink-0 mt-1" />
                    <div>
                      <h2 className="font-semibold text-gray-900">Phone</h2>
                      <a
                        href={`tel:${center.phone}`}
                        className="text-gray-600 hover:text-red-500"
                      >
                        {center.phone}
                      </a>
                    </div>
                  </div>
                )}

                {center.hours && (
                  <div className="flex items-start">
                    <Clock className="h-5 w-5 mr-3 text-gray-400 flex-shrink-0 mt-1" />
                    <div>
                      <h2 className="font-semibold text-gray-900">Hours</h2>
                      <div className="text-gray-600">
                        {Object.entries(center.hours).map(([day, hours]) => (
                          <div key={day} className="flex justify-between">
                            <span className="capitalize">{day}:</span>
                            <span>{hours}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                {center.website && (
                  <div className="flex items-start">
                    <Globe className="h-5 w-5 mr-3 text-gray-400 flex-shrink-0 mt-1" />
                    <div>
                      <h2 className="font-semibold text-gray-900">Website</h2>
                      <a
                        href={center.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-600 hover:text-red-500"
                      >
                        Visit Website
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {center.services && center.services.length > 0 && (
            <div className="bg-white shadow-lg rounded-lg overflow-hidden mt-8">
              <div className="p-8">
                <h2 className="text-xl font-bold text-gray-900 mb-4">
                  Available Services
                </h2>
                <ul className="list-disc list-inside space-y-2 text-gray-600">
                  {center.services.map((service, index) => (
                    <li key={index}>{service}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className="lg:order-2">
          <Map
            centers={[center]}
            center={[center.location.lat, center.location.lon]}
            zoom={15}
          />
        </div>
      </div>
    </div>
  );
}