import React from 'react';
import { Heart } from 'lucide-react';

export function About() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center mb-12">
        <div className="flex justify-center mb-4">
          <Heart className="h-12 w-12 text-red-500" />
        </div>
        <h1 className="text-4xl font-bold text-gray-900 mb-4">About UrgentCareNearMe</h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto">
          We're dedicated to making healthcare more accessible by helping you find urgent care centers when you need them most.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-16">
        <div>
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Our Mission</h2>
          <p className="text-gray-600">
            To simplify access to urgent care services by providing accurate, real-time information about nearby facilities, 
            helping patients make informed decisions about their immediate healthcare needs.
          </p>
        </div>
        <div>
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Our Vision</h2>
          <p className="text-gray-600">
            To create a world where quality healthcare is just a few clicks away, ensuring that everyone can find and access 
            urgent care services quickly and efficiently.
          </p>
        </div>
      </div>

      <div className="bg-gray-50 rounded-lg p-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-6 text-center">Why Choose Us</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            {
              title: 'Accurate Information',
              description: 'We provide up-to-date details about urgent care centers, including wait times and services.',
            },
            {
              title: 'Easy to Use',
              description: 'Our platform is designed to be simple and intuitive, helping you find care quickly.',
            },
            {
              title: 'Always Available',
              description: '24/7 access to information about urgent care centers in your area.',
            },
          ].map((feature, index) => (
            <div key={index} className="text-center">
              <h3 className="text-xl font-semibold text-gray-900 mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}