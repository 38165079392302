import React from 'react';
import { MapPin, Phone, Clock, ExternalLink } from 'lucide-react';
import { Link } from 'react-router-dom';
import { UrgentCare } from '../types';

interface SearchResultsProps {
  centers: UrgentCare[];
}

export function SearchResults({ centers }: SearchResultsProps) {
  return (
    <div className="space-y-4">
      {centers.map((center) => (
        <div
          key={center.id}
          className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow"
        >
          <h3 className="text-xl font-semibold text-gray-900 mb-2">
            {center.name}
          </h3>
          
          <div className="space-y-2 text-gray-600">
            <div className="flex items-start">
              <MapPin className="h-5 w-5 mr-2 flex-shrink-0 text-gray-400" />
              <span>
                {center.address.street}, {center.address.city},{' '}
                {center.address.state} {center.address.zip}
              </span>
            </div>
            
            {center.phone && (
              <div className="flex items-center">
                <Phone className="h-5 w-5 mr-2 text-gray-400" />
                <a
                  href={`tel:${center.phone}`}
                  className="hover:text-red-500"
                >
                  {center.phone}
                </a>
              </div>
            )}
            
            {center.hours && (
              <div className="flex items-start">
                <Clock className="h-5 w-5 mr-2 flex-shrink-0 text-gray-400" />
                <div>
                  <div className="font-semibold">Hours Today:</div>
                  <div>{center.hours[new Date().toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase()]}</div>
                </div>
              </div>
            )}
          </div>

          <div className="mt-4 flex items-center space-x-4">
            <Link
              to={`/center/${center.id}`}
              className="inline-flex items-center text-red-500 hover:text-red-600"
            >
              View Details
              <ExternalLink className="h-4 w-4 ml-1" />
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}