import { useQuery } from '@tanstack/react-query';
import { UrgentCare } from '../types';

const OVERPASS_API = 'https://overpass-api.de/api/interpreter';

const buildOverpassQuery = (lat: number, lon: number, radius: number = 5000) => `
[out:json][timeout:25];
(
  node["amenity"="clinic"](around:${radius},${lat},${lon});
  way["amenity"="clinic"](around:${radius},${lat},${lon});
  node["healthcare"="urgent_care"](around:${radius},${lat},${lon});
  way["healthcare"="urgent_care"](around:${radius},${lat},${lon});
);
out body;
>;
out skel qt;
`;

const fetchUrgentCare = async (lat: number, lon: number): Promise<UrgentCare[]> => {
  const query = buildOverpassQuery(lat, lon);
  const response = await fetch(OVERPASS_API, {
    method: 'POST',
    body: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch urgent care centers');
  }

  const data = await response.json();
  
  // Transform OSM data to our UrgentCare type
  return data.elements
    .filter((element: any) => element.tags)
    .map((element: any) => ({
      id: element.id.toString(),
      name: element.tags.name || 'Urgent Care Center',
      location: {
        lat: element.lat || element.center?.lat,
        lon: element.lon || element.center?.lon,
      },
      address: {
        street: element.tags['addr:street'] || '',
        city: element.tags['addr:city'] || '',
        state: element.tags['addr:state'] || '',
        zip: element.tags['addr:postcode'] || '',
      },
      phone: element.tags.phone || element.tags['contact:phone'],
      hours: element.tags.opening_hours ? {
        monday: element.tags.opening_hours,
        tuesday: element.tags.opening_hours,
        wednesday: element.tags.opening_hours,
        thursday: element.tags.opening_hours,
        friday: element.tags.opening_hours,
        saturday: element.tags.opening_hours,
        sunday: element.tags.opening_hours,
      } : undefined,
      website: element.tags.website || element.tags['contact:website'],
    }))
    .filter((center: UrgentCare) => center.location.lat && center.location.lon);
};

export function useUrgentCare(lat?: number, lon?: number) {
  return useQuery({
    queryKey: ['urgentCare', lat, lon],
    queryFn: () => fetchUrgentCare(lat || 0, lon || 0),
    enabled: !!lat && !!lon,
    staleTime: 1000 * 60 * 5, // 5 minutes
    retry: 3,
  });
}