import { useQuery } from '@tanstack/react-query';
import { UrgentCare } from '../types';

const OVERPASS_API = 'https://overpass-api.de/api/interpreter';

const buildCenterQuery = (id: string) => `
[out:json][timeout:25];
(
  node(${id});
  way(${id});
);
out body;
>;
out skel qt;
`;

const fetchCenterDetails = async (id: string): Promise<UrgentCare | null> => {
  const query = buildCenterQuery(id);
  const response = await fetch(OVERPASS_API, {
    method: 'POST',
    body: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch center details');
  }

  const data = await response.json();
  const element = data.elements[0];

  if (!element) return null;

  return {
    id: element.id.toString(),
    name: element.tags.name || 'Urgent Care Center',
    location: {
      lat: element.lat || element.center?.lat,
      lon: element.lon || element.center?.lon,
    },
    address: {
      street: element.tags['addr:street'] || '',
      city: element.tags['addr:city'] || '',
      state: element.tags['addr:state'] || '',
      zip: element.tags['addr:postcode'] || '',
    },
    phone: element.tags.phone || element.tags['contact:phone'],
    hours: element.tags.opening_hours ? {
      monday: element.tags.opening_hours,
      tuesday: element.tags.opening_hours,
      wednesday: element.tags.opening_hours,
      thursday: element.tags.opening_hours,
      friday: element.tags.opening_hours,
      saturday: element.tags.opening_hours,
      sunday: element.tags.opening_hours,
    } : undefined,
    services: element.tags.healthcare_specialities?.split(';') || [],
    website: element.tags.website || element.tags['contact:website'],
  };
};

export function useCenterDetails(id: string) {
  return useQuery({
    queryKey: ['centerDetails', id],
    queryFn: () => fetchCenterDetails(id),
    enabled: !!id,
    staleTime: 1000 * 60 * 5, // 5 minutes
    retry: 3,
  });
}