import { useQuery } from '@tanstack/react-query';

const NOMINATIM_API = 'https://nominatim.openstreetmap.org/search';

interface GeocodingResult {
  lat: number;
  lon: number;
  display_name: string;
}

export async function geocodeLocation(query: string): Promise<GeocodingResult | null> {
  const params = new URLSearchParams({
    q: query,
    format: 'json',
    limit: '1',
  });

  const response = await fetch(`${NOMINATIM_API}?${params}`, {
    headers: {
      'Accept': 'application/json',
      'User-Agent': 'UrgentCareNearMe/1.0',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to geocode location');
  }

  const results = await response.json();
  if (results.length === 0) return null;

  const result = results[0];
  return {
    lat: parseFloat(result.lat),
    lon: parseFloat(result.lon),
    display_name: result.display_name,
  };
}

export function useGeocoding(query: string) {
  return useQuery({
    queryKey: ['geocoding', query],
    queryFn: () => geocodeLocation(query),
    enabled: !!query,
    staleTime: 1000 * 60 * 60, // 1 hour
  });
}