import React from 'react';
import { LocationData, generateUniqueContent } from '../utils/contentGenerator';

interface LocationContentProps {
  locationData: LocationData;
}

export function LocationContent({ locationData }: LocationContentProps) {
  const content = locationData.city && locationData.state && locationData.zip
    ? generateUniqueContent(locationData.city, locationData.state, locationData.zip)
    : {
        title: `Urgent Care Centers in ${locationData.name}`,
        description: locationData.description,
        content: [locationData.description]
      };

  return (
    <div className="mt-12 bg-white rounded-lg shadow-lg p-8">
      <div className="prose prose-lg max-w-none">
        {content.content.map((paragraph, index) => (
          <p key={index} className="mb-4 text-gray-600 leading-relaxed">
            {paragraph}
          </p>
        ))}

        <div className="mt-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Services Available at Most Locations
          </h2>
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {[
              'Walk-in Medical Care',
              'COVID-19 Testing',
              'Lab Services',
              'X-Ray Services',
              'Pediatric Care',
              'Vaccinations',
              'Physical Exams',
              'Occupational Health',
              'Wound Care',
              'STD Testing'
            ].map((service, index) => (
              <li key={index} className="flex items-center text-gray-600">
                <span className="w-2 h-2 bg-red-500 rounded-full mr-2"></span>
                {service}
              </li>
            ))}
          </ul>
        </div>

        <div className="mt-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Insurance & Payment Options
          </h2>
          <p className="text-gray-600 leading-relaxed">
            Most urgent care centers in {locationData.name} accept major insurance plans including Medicare, Medicaid, and private insurance. Self-pay options and competitive pricing are available for uninsured patients. Contact specific locations for detailed insurance and payment information.
          </p>
        </div>
      </div>
    </div>
  );
}