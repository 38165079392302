import React from 'react';
import { useParams } from 'react-router-dom';
import { useUrgentCare } from '../hooks/useUrgentCare';
import { useLocation } from '../hooks/useLocation';
import { SearchResults } from '../components/SearchResults';
import { Map } from '../components/Map';
import { LocationHeader } from '../components/LocationHeader';
import { LocationSEO } from '../components/LocationSEO';
import { LocationContent } from '../components/LocationContent';
import { Loader2 } from 'lucide-react';

export function LocationPage() {
  const { state, city, zip } = useParams();
  const { locationData, coordinates } = useLocation(state, city, zip);
  const { data: centers = [], isLoading } = useUrgentCare(
    coordinates?.lat,
    coordinates?.lon
  );

  if (!locationData || !coordinates) {
    return null;
  }

  const canonicalUrl = zip
    ? `/urgent-care/zip-code/${zip}`
    : city
    ? `/urgent-care/${state?.toLowerCase()}/${city.toLowerCase().replace(/\s+/g, '-')}`
    : `/urgent-care/${state?.toLowerCase()}`;

  return (
    <>
      <LocationSEO locationData={locationData} canonicalUrl={canonicalUrl} />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <LocationHeader locationData={locationData} />

        {isLoading ? (
          <div className="flex items-center justify-center min-h-[400px]">
            <Loader2 className="h-8 w-8 animate-spin text-red-500" />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              <div>
                <SearchResults centers={centers} />
              </div>
              <div className="lg:sticky lg:top-4 h-[600px]">
                <Map
                  centers={centers}
                  center={[coordinates.lat, coordinates.lon]}
                  zoom={zip ? 13 : city ? 11 : 8}
                />
              </div>
            </div>

            <LocationContent locationData={locationData} />
          </>
        )}
      </div>
    </>
  );
}