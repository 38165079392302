import React from 'react';
import { Heart } from 'lucide-react';

export function Footer() {
  return (
    <footer className="bg-white border-t">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <Heart className="h-6 w-6 text-red-500" />
              <span className="font-bold text-lg">UrgentCareNearMe</span>
            </div>
            <p className="text-gray-600">
              Finding urgent care centers near you when you need them most.
            </p>
          </div>

          <div>
            <h3 className="font-semibold text-gray-900 mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <a href="/" className="text-gray-600 hover:text-red-500">
                  Find Care
                </a>
              </li>
              <li>
                <a href="/about" className="text-gray-600 hover:text-red-500">
                  About Us
                </a>
              </li>
              <li>
                <a href="/services" className="text-gray-600 hover:text-red-500">
                  Services
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-semibold text-gray-900 mb-4">Resources</h3>
            <ul className="space-y-2">
              <li>
                <a href="/blog" className="text-gray-600 hover:text-red-500">
                  Health Blog
                </a>
              </li>
              <li>
                <a href="/faq" className="text-gray-600 hover:text-red-500">
                  FAQs
                </a>
              </li>
              <li>
                <a href="/contact" className="text-gray-600 hover:text-red-500">
                  Contact
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-semibold text-gray-900 mb-4">Legal</h3>
            <ul className="space-y-2">
              <li>
                <a href="/privacy" className="text-gray-600 hover:text-red-500">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="/terms" className="text-gray-600 hover:text-red-500">
                  Terms of Service
                </a>
              </li>
              <li>
                <a href="/accessibility" className="text-gray-600 hover:text-red-500">
                  Accessibility
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-8 pt-8 border-t border-gray-200">
          <p className="text-center text-gray-500">
            © {new Date().getFullYear()} UrgentCareNearMe. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}