export const locations = [
  {
    state: 'California',
    cities: [
      { name: 'Los Angeles', zip: '90012', lat: 34.0522, lon: -118.2437 },
      { name: 'San Francisco', zip: '94102', lat: 37.7749, lon: -122.4194 },
      { name: 'San Diego', zip: '92101', lat: 32.7157, lon: -117.1611 },
      { name: 'Sacramento', zip: '95814', lat: 38.5816, lon: -121.4944 },
      { name: 'San Jose', zip: '95113', lat: 37.3382, lon: -121.8863 },
      { name: 'Oakland', zip: '94612', lat: 37.8044, lon: -122.2712 },
    ],
  },
  {
    state: 'New York',
    cities: [
      { name: 'New York City', zip: '10007', lat: 40.7128, lon: -74.0060 },
      { name: 'Buffalo', zip: '14202', lat: 42.8864, lon: -78.8784 },
      { name: 'Albany', zip: '12207', lat: 42.6526, lon: -73.7562 },
      { name: 'Rochester', zip: '14604', lat: 43.1566, lon: -77.6088 },
      { name: 'Syracuse', zip: '13202', lat: 43.0481, lon: -76.1474 },
    ],
  },
  {
    state: 'Texas',
    cities: [
      { name: 'Houston', zip: '77002', lat: 29.7604, lon: -95.3698 },
      { name: 'Austin', zip: '78701', lat: 30.2672, lon: -97.7431 },
      { name: 'Dallas', zip: '75201', lat: 32.7767, lon: -96.7970 },
      { name: 'San Antonio', zip: '78205', lat: 29.4241, lon: -98.4936 },
      { name: 'Fort Worth', zip: '76102', lat: 32.7555, lon: -97.3308 },
      { name: 'El Paso', zip: '79901', lat: 31.7619, lon: -106.4850 },
    ],
  },
  {
    state: 'Florida',
    cities: [
      { name: 'Miami', zip: '33131', lat: 25.7617, lon: -80.1918 },
      { name: 'Orlando', zip: '32801', lat: 28.5383, lon: -81.3792 },
      { name: 'Tampa', zip: '33602', lat: 27.9506, lon: -82.4572 },
      { name: 'Jacksonville', zip: '32202', lat: 30.3322, lon: -81.6557 },
      { name: 'Fort Lauderdale', zip: '33301', lat: 26.1224, lon: -80.1373 },
    ],
  },
  {
    state: 'Illinois',
    cities: [
      { name: 'Chicago', zip: '60602', lat: 41.8781, lon: -87.6298 },
      { name: 'Springfield', zip: '62701', lat: 39.7817, lon: -89.6501 },
      { name: 'Aurora', zip: '60506', lat: 41.7606, lon: -88.3201 },
      { name: 'Rockford', zip: '61101', lat: 42.2711, lon: -89.0940 },
    ],
  },
  {
    state: 'Pennsylvania',
    cities: [
      { name: 'Philadelphia', zip: '19102', lat: 39.9526, lon: -75.1652 },
      { name: 'Pittsburgh', zip: '15222', lat: 40.4406, lon: -79.9959 },
      { name: 'Harrisburg', zip: '17101', lat: 40.2732, lon: -76.8867 },
      { name: 'Erie', zip: '16501', lat: 42.1292, lon: -80.0851 },
    ],
  },
  {
    state: 'Ohio',
    cities: [
      { name: 'Columbus', zip: '43215', lat: 39.9612, lon: -82.9988 },
      { name: 'Cleveland', zip: '44113', lat: 41.4993, lon: -81.6944 },
      { name: 'Cincinnati', zip: '45202', lat: 39.1031, lon: -84.5120 },
      { name: 'Toledo', zip: '43604', lat: 41.6528, lon: -83.5379 },
    ],
  },
  {
    state: 'Michigan',
    cities: [
      { name: 'Detroit', zip: '48226', lat: 42.3314, lon: -83.0458 },
      { name: 'Grand Rapids', zip: '49503', lat: 42.9634, lon: -85.6681 },
      { name: 'Lansing', zip: '48933', lat: 42.7325, lon: -84.5555 },
      { name: 'Ann Arbor', zip: '48104', lat: 42.2808, lon: -83.7430 },
    ],
  },
  {
    state: 'Georgia',
    cities: [
      { name: 'Atlanta', zip: '30303', lat: 33.7490, lon: -84.3880 },
      { name: 'Savannah', zip: '31401', lat: 32.0809, lon: -81.0912 },
      { name: 'Augusta', zip: '30901', lat: 33.4735, lon: -81.9747 },
      { name: 'Macon', zip: '31201', lat: 32.8407, lon: -83.6324 },
    ],
  },
  {
    state: 'North Carolina',
    cities: [
      { name: 'Charlotte', zip: '28202', lat: 35.2271, lon: -80.8431 },
      { name: 'Raleigh', zip: '27601', lat: 35.7796, lon: -78.6382 },
      { name: 'Durham', zip: '27701', lat: 35.9940, lon: -78.8986 },
      { name: 'Greensboro', zip: '27401', lat: 36.0726, lon: -79.7920 },
    ],
  },
  {
    state: 'Virginia',
    cities: [
      { name: 'Richmond', zip: '23219', lat: 37.5407, lon: -77.4360 },
      { name: 'Virginia Beach', zip: '23451', lat: 36.8529, lon: -75.9780 },
      { name: 'Norfolk', zip: '23510', lat: 36.8508, lon: -76.2859 },
      { name: 'Alexandria', zip: '22314', lat: 38.8048, lon: -77.0469 },
    ],
  },
  {
    state: 'Washington',
    cities: [
      { name: 'Seattle', zip: '98104', lat: 47.6062, lon: -122.3321 },
      { name: 'Spokane', zip: '99201', lat: 47.6587, lon: -117.4260 },
      { name: 'Tacoma', zip: '98402', lat: 47.2529, lon: -122.4443 },
      { name: 'Vancouver', zip: '98660', lat: 45.6387, lon: -122.6615 },
    ],
  },
  {
    state: 'Massachusetts',
    cities: [
      { name: 'Boston', zip: '02108', lat: 42.3601, lon: -71.0589 },
      { name: 'Worcester', zip: '01608', lat: 42.2626, lon: -71.8023 },
      { name: 'Springfield', zip: '01103', lat: 42.1015, lon: -72.5898 },
      { name: 'Cambridge', zip: '02138', lat: 42.3736, lon: -71.1097 },
    ],
  },
  {
    state: 'Arizona',
    cities: [
      { name: 'Phoenix', zip: '85004', lat: 33.4484, lon: -112.0740 },
      { name: 'Tucson', zip: '85701', lat: 32.2226, lon: -110.9747 },
      { name: 'Mesa', zip: '85201', lat: 33.4152, lon: -111.8315 },
      { name: 'Scottsdale', zip: '85251', lat: 33.4942, lon: -111.9261 },
    ],
  },
  {
    state: 'Colorado',
    cities: [
      { name: 'Denver', zip: '80202', lat: 39.7392, lon: -104.9903 },
      { name: 'Colorado Springs', zip: '80903', lat: 38.8339, lon: -104.8214 },
      { name: 'Boulder', zip: '80302', lat: 40.0150, lon: -105.2705 },
      { name: 'Fort Collins', zip: '80524', lat: 40.5853, lon: -105.0844 },
    ],
  },
];