import React from 'react';
import { MapPin, Search, Loader2 } from 'lucide-react';
import { Map } from '../components/Map';
import { SearchResults } from '../components/SearchResults';
import { useUrgentCare } from '../hooks/useUrgentCare';
import { useGeocoding } from '../hooks/useGeocoding';

export function Home() {
  const [location, setLocation] = React.useState('');
  const [searchQuery, setSearchQuery] = React.useState('');
  const [coordinates, setCoordinates] = React.useState<{ lat: number; lon: number } | null>(null);
  const [showResults, setShowResults] = React.useState(false);

  const { data: geocodingResult, isLoading: isGeocoding } = useGeocoding(searchQuery);
  const { data: centers = [], isLoading: isLoadingCenters } = useUrgentCare(
    coordinates?.lat,
    coordinates?.lon
  );

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    setSearchQuery(location);
  };

  React.useEffect(() => {
    if (geocodingResult) {
      setCoordinates({
        lat: geocodingResult.lat,
        lon: geocodingResult.lon,
      });
      setShowResults(true);
    }
  }, [geocodingResult]);

  const getCurrentLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoordinates({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
          setShowResults(true);
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    }
  };

  const isLoading = isGeocoding || isLoadingCenters;

  return (
    <div className="relative">
      {/* Hero Section */}
      {!showResults && (
        <div className="relative h-[600px]">
          <div className="absolute inset-0">
            <img
              src="https://images.unsplash.com/photo-1538108149393-fbbd81895907?auto=format&fit=crop&q=80"
              alt="Medical facility"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-r from-blue-900/90 to-blue-900/75"></div>
          </div>

          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full">
            <div className="flex flex-col justify-center h-full">
              <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6">
                Find Urgent Care
                <br />
                <span className="text-red-400">Near You</span>
              </h1>
              <p className="text-xl text-gray-200 mb-8 max-w-2xl">
                Locate the closest urgent care centers in your area. Get wait times, directions, and book appointments instantly.
              </p>

              <form onSubmit={handleSearch} className="max-w-2xl">
                <div className="flex flex-col sm:flex-row gap-4">
                  <div className="relative flex-grow">
                    <input
                      type="text"
                      placeholder="Enter your location"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      className="w-full px-4 py-3 rounded-lg text-gray-900 placeholder-gray-500 bg-white shadow-md focus:ring-2 focus:ring-red-500 focus:outline-none"
                    />
                    <button
                      type="button"
                      onClick={getCurrentLocation}
                      className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-red-500"
                    >
                      <MapPin className="h-5 w-5" />
                    </button>
                  </div>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="flex items-center justify-center px-6 py-3 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 shadow-md transition-colors disabled:opacity-50"
                  >
                    {isLoading ? (
                      <Loader2 className="h-5 w-5 animate-spin" />
                    ) : (
                      <>
                        <Search className="h-5 w-5 mr-2" />
                        Search
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* Search Results */}
      {showResults && coordinates && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="lg:order-2">
              <Map
                centers={centers}
                center={[coordinates.lat, coordinates.lon]}
              />
            </div>
            <div className="lg:order-1">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">
                Urgent Care Centers Near You
              </h2>
              {isLoading ? (
                <div className="flex items-center justify-center py-12">
                  <Loader2 className="h-8 w-8 animate-spin text-red-500" />
                </div>
              ) : (
                <SearchResults centers={centers} />
              )}
            </div>
          </div>
        </div>
      )}

      {/* Features Section */}
      {!showResults && (
        <div className="bg-white py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-bold text-gray-900 mb-4">
                Why Choose UrgentCareNearMe?
              </h2>
              <p className="text-xl text-gray-600 max-w-2xl mx-auto">
                We make it easy to find and access urgent care services when you need them most.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                {
                  title: 'Real-Time Availability',
                  description: 'Get up-to-date information about wait times and availability at urgent care centers near you.',
                },
                {
                  title: 'Verified Reviews',
                  description: 'Read authentic reviews from patients who have visited each facility.',
                },
                {
                  title: 'Easy Booking',
                  description: 'Book appointments instantly and save your spot in line at participating locations.',
                },
              ].map((feature, index) => (
                <div
                  key={index}
                  className="bg-gray-50 rounded-lg p-8 text-center hover:shadow-lg transition-shadow"
                >
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}