import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { LocationData } from '../types';

interface LocationHeaderProps {
  locationData: LocationData;
}

export function LocationHeader({ locationData }: LocationHeaderProps) {
  return (
    <div className="mb-8">
      <Link
        to="/"
        className="inline-flex items-center text-red-500 hover:text-red-600 mb-4"
      >
        <ArrowLeft className="h-4 w-4 mr-2" />
        Back to Search
      </Link>
      <h1 className="text-4xl font-bold text-gray-900 mb-4">
        Urgent Care in {locationData.name}
      </h1>
      <p className="text-xl text-gray-600">{locationData.description}</p>
    </div>
  );
}